import React from 'react';

export default function BrandName() {
  return (
    <div className="icon_name">
      karyamulti
      <span>sejatiwood.com</span>
    </div>
  );
}
